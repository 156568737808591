import { PageLayout } from '@/components/PageLayout';
import { Flex } from '@chakra-ui/react';
import { Button, ButtonLink, TextHeader } from '@packages/ui';
import { useTranslation } from 'next-i18next';

import { supabase } from '@/lib/supabaseClient';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

const Login = () => {
  const { t } = useTranslation();

  const loginWithTwitter = async () => {
    await supabase.auth.signIn({ provider: 'twitter' }, { redirectTo: '/' });
  };

  return (
    <>
      <Head>
        <title>{`${t('Login')} - Question Market 憧れのあの人に直接質問できる`}</title>
        <meta
          property="og:title"
          content={`${t('Login')} - Question Market 憧れのあの人に直接質問できる`}
        />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}/login`} />
      </Head>
      <PageLayout height="100%">
        <Flex direction="column">
          <TextHeader text={t('Login')} />
          <Flex direction="column" gap="30px" marginTop="35px">
            <Button fontSize="13px" onClick={loginWithTwitter} variant="blue" rounded height="50px">
              {t('Login with Twitter')}
            </Button>
            {/* <Button onClick={() => {}} variant="instagram" rounded height="50px">
              {t('Login with Instagram')}
            </Button> */}
            <ButtonLink href="/login-email" variant="primary" rounded height="50px">
              {t('Login with Email')}
            </ButtonLink>
          </Flex>
          <ButtonLink href="/register" className="underline" isText margin={'35px 0 0 0'}>
            {t('You don’t have an account?')}
          </ButtonLink>
        </Flex>
      </PageLayout>
    </>
  );
};

export default Login;

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      // Will be passed to the page component as props
    },
  };
}
